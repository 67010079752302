
// !speech recognition
import history from './history'
var greetings = ["Taking You To Darkmode"]

let keywords = ["homepage", "about", "experience", 'portfolio', 'projects', 'podcast', 'contact', 'who is', "resume", 'reach out']

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

export const recognition = new SpeechRecognition();


recognition.onstart = function () {
    console.log('start');
};

recognition.onresult = function(event) {
    const current = event.resultIndex;
    const transcript = event.results[current][0].transcript;
    console.log(transcript.toLowerCase());
    let possibleActions = []

    // readout(transcript);

    keywords.map((word) => {
        if (transcript.toLowerCase().includes(word.toLowerCase())) {
            // var actionWord = word
            // if (word ==  'projects') {
            //     actionWord = 'portfolio'
            // } else if (word == 'reach out') {
            //     actionWord='contact'
            // }
            
            possibleActions.push(word)
            return false;
        }
    })

    if (possibleActions.length > 1) {
        console.log(possibleActions)
    } else if(possibleActions.length == 0) {
        readout("Could not understand you, contact levi for more support")
    } else {
        // var routPath = possibleActions[0];
        if (possibleActions[0] == 'projects' || possibleActions[0] == 'project') {
            document.querySelector(`#portfolio`).scrollIntoView({behavior: 'smooth'})
            
        } else if(possibleActions[0].toLowerCase() == 'reach out' ) {
            document.querySelector(`#contact`).scrollIntoView({behavior: 'smooth'})
        } else {
            document.querySelector(`#${possibleActions}`).scrollIntoView({behavior: 'smooth'})
        }
    }
};



// btn.addEventListener('click', () => {
//     recognition.start();
// });

function readout(message){
    // console.log()
    const speech = new SpeechSynthesisUtterance();
    speech.text = message;
    if (message.includes('duck mood')){
        const finaltext = greetings[0];
        speech.text = finaltext;
        window.location.replace("https://udokovicsoft.netlify.com/dark-index.html");
    }

    speech.volume = 1;
    speech.rate = 0.9;
    speech.pitch = 0.7;
    

    window.speechSynthesis.speak(speech);
}


